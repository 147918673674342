import { useQuery } from '@tanstack/react-query'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../components/ui/alert-dialog'
import { useApi } from '../hooks/useApi'
import { useDesignCrud } from '../hooks/useDesignCrud'
import { toast } from 'sonner'

import { CurrentDesignRows } from './current-design-rows'
import { EasyForm } from './easy-form'
import { Table, TableBody } from './ui/table'
import { UndertakerSearch } from './undertaker-search'

export type UserInfo = {
  name: string
  email: string
  phone: string
  externalundertakerid?: number
}

export const SaveDesignDialog = (p: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  const { saveDesign, isSavingDesign } = useDesignCrud()
  const { getUndertakers } = useApi()

  const { data: undertakers } = useQuery({
    queryKey: ['undertakers'],
    queryFn: getUndertakers,
  })

  const onSubmit = async (values: Partial<UserInfo>) => {
    if (
      !values.name ||
      !values.email ||
      !values.phone ||
      (!values.externalundertakerid && undertakers?.isthisneeded)
    ) {
      toast.error('Alle felter er påkrævet')
      return
    }

    await saveDesign(values as UserInfo, () => p.setIsOpen(false))
  }

  return (
    <AlertDialog open={p.isOpen} onOpenChange={p.setIsOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Gem dit design</AlertDialogTitle>
          <AlertDialogDescription>
            <div className='mb-4'>
              Vi skal bruge nogle oplysninger for at gemme dit design
            </div>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="w-full">
          <EasyForm<UserInfo>
            formFields={{
              name: {
                type: 'input',
                label: 'Navn',
                validate: (yup) => yup.string().required('Navn er påkrævet'),
              },
              externalundertakerid: {
                type: 'custom',
                label: 'Bedemand',
                labelClassUnfilled: 'text-red-600',
                render: (formik) => (
                  <UndertakerSearch
                    externalundertakerid={formik.value as number}
                    setExternalUndertakerId={formik.onChange}
                  />
                ),
              },
              email: {
                type: 'input',
                label: 'Email',
                validate: (yup) =>
                  yup
                    .string()
                    .email('Emailen er ikke gyldig')
                    .required('Email er påkrævet'),
              },
              phone: {
                type: 'input',
                label: 'Telefonnummer',
                validate: (yup) =>
                  yup.string().required('Telefonnummer er påkrævet'),
              },
            }}
            onSubmit={onSubmit}
            loading={isSavingDesign}
            submitButtonText='Gem'
            cancelButtonText='Annuller'
            onCancel={() => p.setIsOpen(false)}
          />
        </div>
      </AlertDialogContent>
    </AlertDialog>
  )
}
