import { Button } from '../../components/ui/button'
import { useQuery } from '@tanstack/react-query'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog'
import { useEffect, useState } from 'react'
import { Card } from './card'
import { ArchiveMedia, useApi } from '../../hooks/useApi'
import { emptyPrint, useCoffinEditor } from '../../hooks/useCoffinEditor'
import { FileItem } from '../file-uploader'
import { Input } from '../../components/ui/input'

export type ImageTypes = 'sideready' | 'topready' | 'gableready'

export function ImageArchiveModal(p: {
  type: ImageTypes,
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) {
  const title = {
    'sideready': 'Vælg sidebillede',
    'topready': 'Vælg topbillede',
    'gableready': 'Vælg gavlbillede',
  }

  // Fetch image data from API when dialog is opened
  const { getMedias } = useApi()

  const { data: medias } = useQuery({
    queryKey: ['medias'],
    queryFn: getMedias,
  })

  const [mediasFiltered, setMediasFiltered] = useState<ArchiveMedia[]>([])
  const [searchValue, setSearchValue] = useState('')
  useEffect(() => {
    if (!medias) return
    const baseMedias = medias.results.filter(m => m[p.type as ImageTypes])
    if (searchValue && searchValue.length > 0) {
      setMediasFiltered(baseMedias.filter(m => m.description.toLowerCase().includes(searchValue.toLowerCase())))
    } else {
      setMediasFiltered(baseMedias)
    }
  }, [searchValue, medias])

  useEffect(() => {
    const baseMedias = medias?.results.filter(m => m[p.type as ImageTypes])
    setMediasFiltered(baseMedias ?? [])
  }, [])

  const { currentPrintSide, updatePrints } = useCoffinEditor()

  const setNewPrint = (media: ArchiveMedia) => {
    const updatedPrint = {
      ...emptyPrint,
      fileItem: {
        id: media.filename,
        previewUrl: media.preview,
        thumbnailUrl: media.thumbnail,
        url: media.url,
        name: media.description,
        state: 'archive'
      } as FileItem,
    }

    updatePrints(currentPrintSide, updatedPrint)
  }

  // Reset search value when dialog is closed after 200 ms
  useEffect(() => {
    if (!p.isOpen) {
      setTimeout(() => {
        setSearchValue('')
      }, 200)
    }
  }, [p.isOpen])

  return (
    <Dialog open={p.isOpen} onOpenChange={p.setIsOpen}>
      <DialogContent className='sm:max-w-[425px]' aria-description={title[p.type]}>
        <DialogHeader>
          <DialogTitle>{title[p.type]}</DialogTitle>
          <DialogDescription>
            Vælg et billede fra arkivet herunder
          </DialogDescription>
        </DialogHeader>
        <Input placeholder='Søg' value={searchValue} onChange={(event) => setSearchValue(event.target.value)} className="mb-3" />
        <div className='grid grid-cols-2 gap-2'>
          {mediasFiltered.map((m) => (
            <Card
              key={m.id}
              imageUrl={m.preview}
              title={m.description}
              footer={
                <Button
                  variant='outline'
                  className='m-2'
                  onClick={() => {
                    setNewPrint(m)
                    p.setIsOpen(false)
                  }}
                >
                  Vælg billede
                </Button>
              }
            />
          ))}
        </div>
        <DialogFooter>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              p.setIsOpen(false)
            }}
          >
            Luk
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
