import { Center } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'
import { useCoffinEditor } from '../../hooks/useCoffinEditor'
import { useGeometry } from '../../hooks/useGeometry'
import { useObj } from '../../hooks/useObj'
import { Fragment, useEffect, useState } from 'react'
import * as THREE from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js'

import { CoffinDecal } from './coffin-decal'

export const Coffin = () => {
  const {
    ral,
    ralLid,
    ralHandles,
    ralPlinth,
    ralTopPitch,
    prints,
    model,
    handles: _handles,
    handleCount,
  } = useCoffinEditor()
  const [clear, setClear] = useState(false)

  const objs = useObj(model, _handles, handleCount)

  const [
    top,
    lid,
    topPitch,
    rest,
    left,
    right,
    front,
    back,
    plinth,
    handles,
    greb1,
    greb1end,
  ] = useGeometry(
    useLoader(OBJLoader, [
      objs.top,
      objs.lid,
      objs.topPitch,
      objs.rest,
      objs.left,
      objs.right,
      objs.front,
      objs.back,
      objs.plinth,
      objs.handles,
      objs.greb1,
      objs.greb1end,
    ]) as THREE.Group<THREE.Object3DEventMap>[],
  )

  useEffect(() => {
    setClear(true)
  }, [model])

  // To reset decals
  useEffect(() => {
    if (!clear) return
    setClear(false)
  }, [clear])

  const topPrint = prints.Top
  const leftPrint = prints['Venstre side']
  const rightPrint = prints['Højre side']
  const frontPrint = prints.Front
  const backPrint = prints.Bagside

  const Material = ({ color }: { color?: string }) => (
    <meshStandardMaterial
      roughness={0.15}
      metalness={0.3}
      color={color ?? ral.hex}
    />
  )

  const isOrbit = model === 'orbit'
  const isGreb = _handles === 'greb'

  if (clear) return null

  return (
    <Center top rotation={[0, Math.PI / 2, 0]}>
      {model === 'orbit' && (
        <mesh castShadow receiveShadow geometry={rest}>
          <Material />
        </mesh>
      )}

      {model !== 'orbit' && (
        <mesh castShadow receiveShadow geometry={topPitch}>
          <Material color={ralTopPitch.hex} />
        </mesh>
      )}

      {model !== 'orbit' && (
        <mesh castShadow receiveShadow geometry={plinth}>
          <Material color={ralPlinth.hex} />
        </mesh>
      )}

      {!isGreb && (
        <mesh castShadow receiveShadow geometry={handles}>
          <Material
            color={
              _handles === 'stropper'
                ? ['Hvid', 'RAL 8001'].includes(ralHandles.ral)
                  ? ralHandles.hex
                  : '#ffffff'
                : ralHandles.hex
            }
          />
        </mesh>
      )}

      {/* We reuse the handle obj to reduce data needed to be downloaded - 22 mb saved. We can consider making the file more simple */}

      {isGreb &&
        ['normal', 'mirrored'].map((side, i) => (
          <Fragment key={i}>
            {(isOrbit
              ? [
                  [0, -0.012, 0.004],
                  [-0.77, -0.012, 0.004],
                  [0.77, -0.012, 0.004],
                ]
              : [
                  [0, 0, 0],
                  [-0.71, 0, 0],
                  [0.71, 0, 0],
                ]
            ).map((position, i) => (
              <mesh
                castShadow
                receiveShadow
                geometry={greb1}
                position={
                  new THREE.Vector3(
                    ...position.map((p, i) =>
                      side === 'mirrored' && i == 2 ? p - 0.008 : p,
                    ),
                  )
                }
                key={i}
                rotation={[0, side === 'mirrored' ? Math.PI : 0, 0]}
              >
                <Material color={ralHandles.hex} />
              </mesh>
            ))}
          </Fragment>
        ))}

      {isGreb &&
        ['normal', 'mirrored'].map((side, i) => (
          <Fragment key={i}>
            {(isOrbit
              ? []
              : handleCount === 10
                ? [
                    [0, 0, -0.1415],
                    [0, 0, 0.1415],
                  ]
                : handleCount === 8
                  ? [[0, 0, 0]]
                  : []
            ).map((position, i) => (
              <mesh
                castShadow
                receiveShadow
                geometry={greb1end}
                position={new THREE.Vector3(...position)}
                key={i}
                rotation={[0, side === 'mirrored' ? Math.PI : 0, 0]}
              >
                <Material color={ralHandles.hex} />
              </mesh>
            ))}
          </Fragment>
        ))}

      {/* With print */}

      <mesh receiveShadow geometry={left}>
        <Material />
        {leftPrint?.fileItem?.previewUrl && (
          <CoffinDecal
            url={leftPrint.fileItem?.previewUrl}
            pos={
              new THREE.Vector3(
                0 + (leftPrint.offsetX ?? 0) * -1.5,
                -0.28 + (leftPrint.offsetY ?? 0),
                -0.3,
              )
            }
            rot={
              new THREE.Euler(
                0,
                Math.PI,
                0 + (Math.PI / 2) * leftPrint.rotation,
              )
            }
            scale={leftPrint.zoom ?? 1}
          />
        )}
      </mesh>

      <mesh castShadow={true} receiveShadow={true} geometry={right}>
        <Material />
        {rightPrint?.fileItem?.previewUrl && (
          <CoffinDecal
            url={rightPrint.fileItem?.previewUrl}
            pos={
              new THREE.Vector3(
                0 + (rightPrint.offsetX ?? 0) * 1.5,
                -0.28 + (rightPrint.offsetY ?? 0),
                0.3,
              )
            }
            rot={new THREE.Euler(0, 0, 0 + (Math.PI / 2) * rightPrint.rotation)}
            scale={rightPrint.zoom ?? 1}
          />
        )}
      </mesh>

      <mesh castShadow receiveShadow geometry={front}>
        <Material />
        {frontPrint?.fileItem?.previewUrl && (
          <CoffinDecal
            url={frontPrint.fileItem?.previewUrl}
            pos={
              new THREE.Vector3(
                -1,
                -0.28 + (frontPrint.offsetY ?? 0),
                0 + (frontPrint.offsetX ?? 0),
              )
            }
            rot={
              new THREE.Euler(
                0 + (Math.PI / 2) * frontPrint.rotation * -1,
                -Math.PI / 2,
                0,
              )
            }
            scale={frontPrint.zoom ?? 1}
          />
        )}
      </mesh>

      <mesh castShadow receiveShadow geometry={back}>
        <Material />
        {backPrint?.fileItem?.previewUrl && (
          <CoffinDecal
            url={backPrint.fileItem?.previewUrl}
            pos={
              new THREE.Vector3(
                1,
                -0.28 + (backPrint.offsetY ?? 0),
                0 + (backPrint.offsetX ?? 0) * -1,
              )
            }
            rot={
              new THREE.Euler(
                0 + (Math.PI / 2) * backPrint.rotation,
                Math.PI / 2,
                0,
              )
            }
            scale={backPrint.zoom ?? 1}
          />
        )}
      </mesh>

      <mesh castShadow={false} receiveShadow={false} geometry={lid}>
        <Material color={ralLid.hex} />
      </mesh>

      <mesh castShadow={false} receiveShadow={false} geometry={top}>
        <Material color={ralLid.hex} />
        {topPrint?.fileItem?.previewUrl && (
          <CoffinDecal
            url={topPrint.fileItem?.previewUrl}
            pos={
              new THREE.Vector3(
                0 + (topPrint.offsetY ?? 0) * 1.5,
                0.02,
                0 + (topPrint.offsetX ?? 0),
              )
            }
            rot={
              new THREE.Euler(
                -Math.PI / 2,
                0,
                -Math.PI / 2 + (Math.PI / 2) * topPrint.rotation,
              )
            }
            scale={topPrint.zoom ?? 1}
          />
        )}
      </mesh>
    </Center>
  )
}
