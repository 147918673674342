'use client'

import { useQuery } from '@tanstack/react-query'
import { useApi } from '../hooks/useApi'
import { cn } from '../lib/utils'
import { Check, ChevronsUpDown } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'

import { Button } from './ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from './ui/command'
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover'

export function UndertakerSearch(p: {
  externalundertakerid: number | undefined
  setExternalUndertakerId: (id: number) => void
}) {
  const { getUndertakers } = useApi()

  const { data: undertakers } = useQuery({
    queryKey: ['undertakers'],
    queryFn: getUndertakers,
  })

  const [open, setOpen] = useState(false)

  const RenderUndertaker = ({
    undertaker,
  }: {
    undertaker: NonNullable<typeof undertakers>['results'][0]
  }) => {
    return (
      <div className='text-sm'>
        <b>{undertaker.name}</b>
        <div className='text-xs'>
          {undertaker.address}, {undertaker.zipcity}
        </div>
        <div className='text-xs'>Tlf: {undertaker.phone}</div>
      </div>
    )
  }

  const options = undertakers?.results

  const listRef = useRef<HTMLDivElement>(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0; // Reset scroll to top on render
    }
  }, [searchValue]);

  if (!undertakers?.isthisneeded) return null

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant='outline'
          role='combobox'
          aria-expanded={open}
          className='w-[100%] justify-between whitespace-break-spaces text-left'
        >
          {p.externalundertakerid
            ? (options ?? []).find(
                (option) => option.id === p.externalundertakerid,
              )?.name
            : 'Vælg bedemand...'}
          <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[400px] p-0' align='start'>
        <Command>
          <CommandInput placeholder='Vælg bedemand...' value={searchValue} onValueChange={setSearchValue} className="text-[16px]" />
          <CommandList ref={listRef}>
            <CommandEmpty>Søgningen gav ingen resultater</CommandEmpty>
            <CommandGroup>
              {(options ?? []).map((option) => (
                <CommandItem
                  key={option.id}
                  value={`${option.name} ${option.address} ${option.zipcity} ${option.phone}`}
                  className='cursor-pointer'
                  onSelect={() => {
                    p.setExternalUndertakerId(option.id)
                    setOpen(false)
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      p.externalundertakerid === option.id
                        ? 'opacity-100'
                        : 'opacity-0',
                    )}
                  />
                  <RenderUndertaker undertaker={option} />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
